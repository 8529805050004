<!--
 * @Author: huwanjun
 * @Date: 2021-04-07 11:01:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-24 14:28:17
 * @Description: 添加客户
-->

<template>
  <NeoPageLayout>
    <div class="newCompany">
      <h2 class="title">{{ $t('newCompany.title') }}</h2>
      <!-- Step 1 -->
      <template v-if="step === 1">
        <a-form-model :colon="false" v-bind="layout.form" ref="oneForm" :rules="rules" :model="formdata">
          <div class="addStepOne">
            <a-row>
              <a-col :span="8">
                <a-form-model-item
                  prop="pId"
                  :label="$t('newCompany.select_company')"
                  :label-col="{ flex: '180px' }"
                  :wrapper-col="{ flex: 'auto' }"
                  :rules="[validate.required]"
                >
                  <a-input v-model="formdata.pId"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="8" class="ml-6" style="line-height: 40px">
                <a-button style="min-width: 100px" type="primary" @click="onSync" :loading="syncLoading">{{
                  $t('sync')
                }}</a-button>
              </a-col>
            </a-row>
            <div class="customer-info mt-4" v-if="show">
              <a-descriptions bordered :column="2" size="small">
                <a-descriptions-item :label="$t('newCompany.company_code')">
                  {{ cusAgencyInfo.code | defined }}
                </a-descriptions-item>
                <a-descriptions-item :label="$t('newCompany.spm')">{{
                  cusAgencyInfo.supplierSpmName | defined
                }}</a-descriptions-item>
                <a-descriptions-item :label="$t('newCompany.company_name')">
                  {{ cusAgencyInfo.name | defined }}
                </a-descriptions-item>
                <a-descriptions-item :label="$t('newCompany.supplier_name')">
                  {{ cusAgencyInfo.supplierName | defined }}
                </a-descriptions-item>
                <a-descriptions-item :label="$t('newCompany.company_address')">
                  {{ cusAgencyInfo.address | defined }}
                </a-descriptions-item>
                <a-descriptions-item :label="$t('newCompany.supplier_address')"
                  >{{ cusAgencyInfo.supplierAddress | defined }}
                </a-descriptions-item>
                <a-descriptions-item :label="$t('newCompany.currency')"
                  >{{ cusAgencyInfo.currency | defined }}
                </a-descriptions-item>
                <a-descriptions-item> </a-descriptions-item>
              </a-descriptions>
            </div>
            <a-button class="mt-4 next-btn" type="primary" @click="onNextOne">{{ $t('next') }}</a-button>
          </div>
        </a-form-model>
      </template>
      <!-- Step 2 -->
      <template v-if="step === 2">
        <a-form-model :colon="false" v-bind="layout.form" ref="twoForm" :rules="rules" :model="form">
          <div class="addStepTwo">
            <h3 class="prompt">{{ $t('newPline.prompt_new_Pline') }}</h3>
            <NeoTitle :text="$t('newPline.title_basic_info')" class="mt-4 mb-3" />
            <div class="baseinfo">
              <!-- 产线名 -->
              <a-row>
                <a-col :span="8">
                  <a-form-model-item prop="name" :label="$t('newPline.Pline_name')">
                    <a-input v-model="form.name" :placeholder="$t('newPline.Pline_name_holder')"></a-input>
                  </a-form-model-item>
                </a-col>
                <!-- 行业领域 -->
                <a-col :span="8" offset="4">
                  <a-form-model-item prop="field" :label="$t('newPline.industry_field')">
                    <a-cascader
                      v-model="form.field"
                      :options="industryList"
                      :fieldNames="fielName"
                      :placeholder="$t('newPline.industry_field_holder')"
                    ></a-cascader>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
            <NeoTitle :text="$t('newPline.title_product_dock')" />
            <a-radio-group v-model="neoVersion">
              <a-space :size="80">
                <a-radio class="linkNeo" :value="1">{{ $t('newPline.dock_new_Neo') }}</a-radio>
                <a-radio class="linkNeo" :value="0">{{ $t('newPline.dock_Neo') }}</a-radio>
              </a-space>
            </a-radio-group>
            <NeoTitle :text="$t('newPline.title_offer_config')" />
            <p class="price">
              {{ $t('newPline.default_offer_mode') }}：<span class="price-method">{{
                $t('newPline.artificial_offer')
              }}</span>
            </p>
            <!-- 报价配置 -->
            <div class="price-info">
              <template v-if="neoVersion === 0">
                <a-descriptions bordered layout="vertical" :column="9" size="small">
                  <a-descriptions-item :label="$t('PlineDetails.match_range')">{{
                    $t('PlineDetails.discount_ratio')
                  }}</a-descriptions-item>
                  <a-descriptions-item
                    v-for="(item, index) in form.discountList"
                    :key="item.discountRange"
                    :label="item.discountRange"
                  >
                    <a-form-model-item
                      style="margin-bottom: 0px"
                      class="table-item"
                      :prop="`discountList.${index}.discountValue`"
                      :rules="[validate.required, validate.float_2]"
                    >
                      <a-input size="small" v-model="form.discountList[index].discountValue"></a-input>
                    </a-form-model-item>
                  </a-descriptions-item>
                </a-descriptions>
              </template>
              <!-- 新版的报价配置 -->
              <template v-else>
                <a-descriptions bordered layout="vertical" :column="11" size="small">
                  <a-descriptions-item :label="$t('PlineDetails.match_range')">{{
                    $t('PlineDetails.discount_ratio')
                  }}</a-descriptions-item>
                  <a-descriptions-item
                    v-for="(item, index) in form.discountList_2"
                    :key="`s${index}`"
                    :label="item.discountRange"
                  >
                    <a-form-model-item
                      style="margin-bottom: 0px"
                      class="table-item"
                      :prop="`discountList_2.${index}.discountValue`"
                      :rules="[validate.required, validate.float_2]"
                    >
                      <a-input size="small" v-model="form.discountList_2[index].discountValue"></a-input>
                    </a-form-model-item>
                  </a-descriptions-item>
                </a-descriptions>
              </template>
            </div>
            <div class="mt-4">
              <a-button class="neo-btn-primary mr-2 prev-btn" @click="step = 1">{{ $t('prev') }}</a-button>
              <a-button type="primary" class="next-btn" @click="onNextTwo">{{ $t('next') }}</a-button>
            </div>
          </div>
        </a-form-model>
      </template>
      <!-- 结果弹窗 -->
      <a-modal :visible="showResult" :footer="null" centered @cancel="showResult = false">
        <a-result
          status="success"
          :title="$t('newCompany.success_title')"
          :sub-title="$t('newCompany.sub_title', { name: form.name })"
        >
          <template #extra>
            <a-button key="console" type="primary" @click="onBack">{{ $t('newCompany.back_to') }}</a-button>
            <a-button key="join" @click="onGoDetails(newId)">{{ $t('newCompany.go_agency_details') }}</a-button>
          </template>
        </a-result>
      </a-modal>
      <!-- Step 3 -->
      <template v-if="step === 3 && neoVersion === 0">
        <a-form-model :colon="false" ref="threeForm" :rules="rules" :model="form">
          <div class="addStepThree">
            <h3 class="prompt">{{ $t('newPline.prompt_dock_Neo') }}</h3>
            <NeoTitle :text="$t('newPline.title_basic_config')" class="mt-4 mb-3" />
            <div class="baseconfig">
              <a-row>
                <a-col :span="8">
                  <!-- client Project -->
                  <a-form-model-item
                    prop="produceClientProject"
                    :label="$t('newPline.client_project')"
                    v-bind="layout.item"
                  >
                    <a-select
                      show-search
                      :filter-option="filterOption"
                      v-model="form.produceClientProject"
                      @change="handleClientChange"
                      :placeholder="$t('newPline.client_project_holder')"
                      :labelInValue="true"
                    >
                      <a-select-option v-for="item in clientProjectList" :key="item.pid">{{
                        `${item.projectName}(${item.pid})`
                      }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8">
                  <a-form-model-item prop="produceJobType" :label="$t('newPline.job_type')" v-bind="layout.item">
                    <a-select v-model="form.produceJobType" :placeholder="$t('newPline.job_type_holder')">
                      <a-select-option v-for="item in jobTypeList" :key="item.jobTypeCode">{{
                        item.jobTypeName
                      }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="8" :offset="4">
                  <!-- ratio -->
                  <a-form-model-item
                    prop="produceRatio"
                    :rules="[validate.required]"
                    :label="$t('newPline.ratio')"
                    v-bind="layout.item"
                  >
                    <a-select
                      v-model="form.produceRatio"
                      :placeholder="$t('newPline.ratio_holder')"
                      :labelInValue="true"
                    >
                      <a-select-option v-for="item in ratioList" :key="item.id" :value="item.id">{{
                        item.name
                      }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
            <NeoTitle :text="$t('newPline.title_corpus_config')" />
            <div class="corpusconfig mt-1">
              <a-row class="mb-3">
                <a-col :span="8" class="mr-3">
                  <a-form-model-item prop="produceMtEngine" :label="$t('newPline.engine')" v-bind="layout.item">
                    <a-select
                      v-model="form.produceMtEngine"
                      :labelInValue="true"
                      :placeholder="$t('newPline.engine_holder')"
                    >
                      <a-select-option v-for="item of mtEngineList" :key="item.code">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item>
                    <a-checkbox style="line-height: 40px" v-model="form.produceMtApply">{{
                      $t('newPline.apply_result')
                    }}</a-checkbox>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-table
                class="head-bg no-br"
                :data-source="form.documentList"
                :pagination="false"
                bordered
                size="middle"
                rowKey="documentType"
              >
                <!-- 文档类型 -->
                <a-table-column :title="$t('newPline.doc_type')" width="30%" key="documentType">
                  <template slot-scope="text, record, index">{{ docType[index].dictLabel }}</template>
                </a-table-column>
                <a-table-column :title="$t('newPline.tm_and_term')" key="produceTmTermGroup" width="20%">
                  <template slot-scope="text, record, index">
                    <a-form-model-item
                      class="table-item"
                      :prop="`documentList.` + index + `.produceTmTermGroup`"
                      :rules="[validate.required]"
                      v-bind="layout.item"
                    >
                      <a-select
                        :placeholder="$t('newPline.tm_and_term_holder')"
                        size="small"
                        v-model="form.documentList[index].produceTmTermGroup"
                        :labelInValue="true"
                      >
                        <a-select-option v-for="item in tmTermGroupList" :key="item.groupId" :value="item.groupId">{{
                          item.groupName
                        }}</a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </template>
                </a-table-column>
                <!-- 反向应用 -->
                <a-table-column :title="$t('newPline.tm_reverse')" key="produceTmReverseApply	" align="center">
                  <template slot-scope="text, record, index">
                    <a-checkbox v-model="form.documentList[index].produceTmReverseApply"></a-checkbox>
                  </template>
                </a-table-column>
              </a-table>
            </div>
            <NeoTitle :text="$t('newPline.title_person_config')" class="mt-4 mb-3" />
            <a-row>
              <a-col :span="8">
                <a-form-model-item prop="supplierPm" :label="$t('newPline.delivery_manager')" v-bind="layout.item">
                  <a-select
                    show-search
                    v-model="form.supplierPm"
                    :placeholder="$t('newPline.delivery_manager_holder')"
                    :filter-option="filterOption"
                  >
                    <a-select-option v-for="item in pmList" :key="item.id" :value="item.id">{{
                      `${item.realName}(${item.email})`
                    }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="8" :offset="4">
                <a-form-model-item
                  prop="produceManagerList"
                  :label="$t('newPline.product_manager')"
                  v-bind="layout.item_long"
                >
                  <a-select
                    v-model="form.produceManagerList"
                    mode="multiple"
                    :filter-option="filterOption"
                    :placeholder="$t('newPline.product_manager_holder')"
                  >
                    <a-select-option v-for="item in produceManagerList" :key="item.username" :valu="item.username">{{
                      `${item.displayName}(${item.username})`
                    }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>

            <div class="btn-container">
              <a-button class="neo-btn-primary mr-2 prev-btn" @click="step = 2">{{ $t('prev') }}</a-button>
              <a-button type="primary" class="submit-btn" @click="onSubmit" :loading="loading">{{
                $t('submit')
              }}</a-button>
            </div>
          </div>
        </a-form-model>
      </template>
      <!-- Step 3 New_Neo -->
      <template v-if="step === 3 && neoVersion === 1">
        <a-form-model :colon="false" :model="newNeoForm" ref="newNeoThreeForm">
          <div class="addStepThree_new">
            <h3 class="prompt">{{ $t('newPline.Neotrans_product_config') }}</h3>
            <NeoTitle :text="$t('newPline.title_basic_info')" class="mt-3 mb-3" />
            <div class="basic_config">
              <a-row>
                <a-col :span="12">
                  <a-form-model-item
                    prop="program"
                    :label="$t('newPline.program')"
                    :label-col="{ flex: '70px' }"
                    :wrapper-col="{ flex: 'auto' }"
                    :rules="[validate.required]"
                  >
                    <a-select
                      v-model="newNeoForm.program"
                      mode="multiple"
                      :filter-option="filterOption"
                      @change="onSelectProgram"
                    >
                      <a-select-option v-for="(item, index) in programList" :key="index" :value="item.id">{{
                        item.name
                      }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12" style="line-height: 40px">
                  <a-button type="primary" class="ml-4">{{ $t('newPline.btn_check') }}</a-button>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12">
                  <a-form-model-item
                    :label="$t('newPline.new_program_client_project')"
                    :label-col="{ flex: '150px' }"
                    :wrapper-col="{ flex: 'auto' }"
                    prop="clientProject"
                    :rules="[validate.required]"
                  >
                    <a-select v-model="newNeoForm.clientProject">
                      <a-select-option v-for="item in namePidList" :key="item" :value="item">{{
                        item.name
                      }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12" style="line-height: 40px">
                  <a-tooltip placement="right">
                    <template slot="title">
                      <span>{{ $t('newPline.tooltop') }}</span>
                    </template>
                    <a-icon type="question-circle" class="help ml-4" />
                  </a-tooltip>
                </a-col>
              </a-row>
            </div>
            <div>
              <a-table class="head-bg no-br" bordered :pagination="pagination" size="middle" :data-source="tableData">
                <a-table-column :title="$t('newPline.table_program')">
                  <template slot-scope="text">{{ text.name }}</template>
                </a-table-column>
                <a-table-column :title="$t('newPline.table_source')">
                  <template slot-scope="text">{{ text.sourceCode }}</template>
                </a-table-column>
                <a-table-column :title="$t('newPline.table_content_type')">
                  <template slot-scope="text">{{ text.componentName }}</template>
                </a-table-column>
              </a-table>
            </div>
            <!-- 注意 -->
            <div class="attention">
              <p>{{ $t('newPline.attention') }}{{ $t('newPline.attention_1') }}</p>
              <!-- <p>{{ $t('newPline.attention_1') }}</p>
              <p>{{ $t('newPline.attention_2') }}</p>
              <p>{{ $t('newPline.attention_1') }}</p> -->
            </div>
            <div class="basic_config">
              <NeoTitle :text="$t('newPline.title_basic_info')" class="mt-4 mb-2" />
              <a-row>
                <a-col :span="12">
                  <a-form-model-item
                    :label="$t('newPline.delivery_manager')"
                    :label-col="{ flex: '70px' }"
                    :wrapper-col="{ flex: 'auto' }"
                    prop="supplierPm"
                    :rules="[validate.required]"
                  >
                    <a-select v-model="newNeoForm.supplierPm" :filter-option="filterOption" show-search>
                      <a-select-option v-for="item in pmList" :key="item.id" :value="item.id">{{
                        `${item.realName}(${item.email})`
                      }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
          </div>
          <div class="btn-container">
            <a-button class="neo-btn-primary mr-2 prev-btn" @click="step = 2">{{ $t('prev') }}</a-button>
            <a-button type="primary" class="submit-btn" @click="onSubmit" :loading="loading" v-if="neoVersion === 0">{{
              $t('submit')
            }}</a-button>
            <a-button type="primary" class="submit-btn" @click="onSubmitNewNeo" :loading="loading" v-else>{{
              $t('submit')
            }}</a-button>
          </div>
        </a-form-model>
      </template>
    </div>
  </NeoPageLayout>
</template>
]

<script>
import { mapState } from 'vuex'
const { validate } = window.$g

export default {
  name: 'newCompany',
  data() {
    return {
      tableData: [],
      step: 1,
      validate: window.$g.validate,
      // 企业描述table
      show: false,
      showResult: false,
      // 提交弹框
      submitVisible: false,
      loading: false,
      syncLoading: false,
      cusAgencyInfo: {},
      options: [],
      clientProjectList: [],
      jobTypeList: [],
      ratioList: [],
      mtEngineList: [],
      fielName: {
        value: 'dictValue',
        label: 'dictLabel',
        children: 'children',
      },
      formdata: {
        pId: undefined,
      },
      // 表单样式
      layout: {
        form: {
          labelCol: { flex: '80px' },
          wrapperCol: { flex: 'auto' },
        },
        item: {
          labelCol: { flex: '100px' },
          wrapperCol: { flex: 'auto' },
        },
        item_long: {
          labelCol: { flex: '150px' },
          wrapperCol: { flex: 'auto' },
        },
      },
      // 表单数据
      form: {
        accoutId: undefined, // 客户id
        address: undefined, //地址
        currency: undefined, // 币种
        cusAgencyName: undefined, // 公司名称
        agencyId: undefined, //客户实体
        name: undefined, //产线名
        field: undefined, //行业领域
        discountList: Array.from({ length: 8 }).map(() => ({
          discountRange: '',
          discountValue: '',
        })),
        discountList_2: Array.from({ length: 10 }).map(() => ({
          discountRange: '',
          discountValue: '',
          discountRangeMapping: '',
        })),
        produceClientProject: undefined, //Client Project
        produceJobType: undefined, //Job Type
        produceRatio: undefined, //Ratio
        produceMtEngine: undefined, //机翻引擎
        supplierPm: undefined, //交付经理
        produceMtApply: false,
        produceManagerList: [], //生产管理员
        documentList: Array.from({ length: 2 }).map(() => ({
          documentType: '',
          produceTmReverseApply: false,
          produceTmTermGroup: undefined,
        })),
        newId: undefined,
      },
      // 表单验证规则
      rules: {
        agencyId: [validate.required],
        name: [validate.size_20, validate.required], // 产线名
        field: [validate.required], // 行业领域
        produceClientProject: [validate.required],
        produceJobType: [validate.required],
        produceRatio: [validate.required],
        // produceMtEngine: [validate.required],
        supplierPm: [validate.required],
        produceManagerList: [validate.required],
      },
      newNeoForm: {
        program: undefined,
        clientProject: undefined,
        supplierPm: undefined,
      },
      pmList: [],
      produceManagerList: [],
      tmTermGroupList: [],
      pagination: {
        current: 1,
        size: 'middle',
        total: 0,
        pageSize: 6,
        style: {
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        },
        // eslint-disable-next-line no-unused-vars
        onChange: (page) => {
          this.pagination.current = page
        },
      },
      neoVersion: 1,
      programList: [],
      namePidList: [],
    }
  },
  mounted() {
    this.getJobTypeList()
    this.getMtEngineList()
    this.order_ratio.forEach((item, index) => {
      this.form.discountList[index].discountRange = item.dictLabel
      this.form.discountList[index].discountValue = item.dictValue
    })
    this.trans_order_ratio.forEach((item, index) => {
      this.form.discountList_2[index].discountRange = item.dictLabel
      this.form.discountList_2[index].discountValue = item.dictValue
      this.form.discountList_2[index].discountRangeMapping = item.remark
    })
  },
  computed: {
    ...mapState('app', {
      industryList: (state) => state.industryList,
      userInfo: (state) => state.userInfo,
    }),
    order_ratio: function () {
      const data = this.$store.getters['app/getDictByType']('ORDER_RATIO')
      return data
    },
    trans_order_ratio: function () {
      const data = this.$store.getters['app/getDictByType']('TRANS_2_ORDER_RATIO')
      return data
    },
    docType: function () {
      return this.$store.getters['app/getDictByType']('DOC_TYPE')
    },
  },
  methods: {
    // 获取clientProject列表
    async getClientProjectList() {
      try {
        const data = await this.$http('clientProjectList', {
          clientName: this.cusAgencyInfo.name,
        })
        this.clientProjectList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 获取jobType列表
    async getJobTypeList() {
      try {
        const data = await this.$http('jobTypeList')
        this.jobTypeList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 处理clientProject选择
    handleClientChange(val) {
      this.getRatioList(val.key)
      this.getTmTermGroupList(val.key)
      this.form.produceRatio = undefined
      this.form.documentList.forEach((item) => {
        item.produceTmTermGroup = undefined
      })
    },
    // 获取ratio列表
    async getRatioList(val) {
      try {
        const data = await this.$http('ratioList', {
          pid: val,
        })
        this.ratioList = data.filter((item) => {
          if (item.name == 'System Default') {
            item.id = -1
          }
          return item.name
        })
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 获取 mtEngine列表
    async getMtEngineList() {
      try {
        const data = await this.$http('mtEngineList')
        this.mtEngineList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 根据id获取客户实体数据
    async getCusAgencyInfo() {
      this.syncLoading = true
      try {
        const data = await this.$http('agencyInfoFromSERP', {
          pId: this.formdata.pId,
        })
        this.syncLoading = false
        if (data[0]) {
          this.cusAgencyInfo = data[0]
        } else {
          this.$message.error(this.$t('newPline.pid_exsit_prompt'))
        }
      } catch (err) {
        this.syncLoading = false
        this.$httpNotify(err)
      }
    },
    // 同步按钮
    onSync() {
      this.$refs.oneForm.validate((valid) => {
        if (valid) {
          this.getCusAgencyInfo()
          this.show = true
        }
      })
    },
    // 获取TM & Term 组的数据
    async getTmTermGroupList(val) {
      try {
        const data = await this.$http('tmTermGroupList', {
          pid: val,
        })
        this.tmTermGroupList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 获取交付经理
    async getPmList() {
      try {
        const data = await this.$http('getPmList')
        this.pmList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 获取生产管理成员
    async getProduceManagerList() {
      try {
        const data = await this.$http('getproduceManagerList')
        this.produceManagerList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 搜索生产管理员
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 获取program列表
    async getProgramList() {
      try {
        const data = await this.$http('programList', {
          agencyId: this.cusAgencyInfo.id, //,'1400783905408851969'
          productId: '',
          name: this.cusAgencyInfo.name,
        })
        this.programList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 对接鲸译-获取clientproject
    async getNamePidList() {
      try {
        const data = await this.$http('namePidList', {
          name: this.cusAgencyInfo.name, //'nihao'
        })
        this.namePidList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // step 1 下一步
    onNextOne() {
      this.$refs.oneForm.validate((valid) => {
        if (valid) {
          if (this.show && this.cusAgencyInfo.supplierSpm != null) {
            // 高级交付经理与操作用户不同，则点击下一步会报错，提示
            if (this.cusAgencyInfo.id != null) {
              if (this.userInfo.id === this.cusAgencyInfo.supplierSpm) {
                this.onGoDetails(this.cusAgencyInfo.id)
              } else {
                this.$message.error(this.$t('newPline.sync_error_prompt'))
              }
            } else {
              this.step = 2
              this.getProgramList()
              this.getNamePidList()
            }
          } else {
            this.$message.info(this.$t('newPline.no_sync_prompt'))
          }
        }
      })
    },
    // step2 下一步
    onNextTwo() {
      this.$refs.twoForm.validate((valid) => {
        if (valid) {
          this.step = 3
          this.docType.forEach((item, index) => {
            this.form.documentList[index].documentType = item.dictValue
          })
          this.getPmList()
          this.getProduceManagerList()
          this.getClientProjectList()
        } else {
          return false
        }
      })
    },
    // 提交按钮
    onSubmit() {
      this.$refs.threeForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            const proMt = this.form.produceMtApply ? 1 : 0
            // eslint-disable-next-line no-unused-vars
            const data = await this.$http('newProductionLine', {
              ...this.form,
              accoutId: this.cusAgencyInfo.accoutId,
              documentList: this.form.documentList.map((item) => ({
                documentType: item.documentType,
                produceTmReverseApply: item.produceTmReverseApply ? 1 : 0,
                produceTmTermGroup: item.produceTmTermGroup.key,
                produceTmTermGroupName: item.produceTmTermGroup.label,
              })),
              field: this.form.field[this.form.field.length - 1],
              produceManagerList: this.form.produceManagerList.map((item) => {
                let data = []
                this.produceManagerList.forEach((v) => {
                  if (v.username === item) {
                    let result = {}
                    result.producePm = v.username
                    result.producePmEmails = v.emails
                    result.producePmName = v.displayName
                    data.push(result)
                  }
                })
                return data[0]
              }),
              produceVersion: 1,
              quoteType: 1,
              produceMtApply: proMt,
              produceJobMode: '1',
              produceClientProject: this.form.produceClientProject.key,
              produceClientProjectName: this.form.produceClientProject.label,
              produceMtEngine: this.form?.produceMtEngine?.key || '',
              produceMtEngineName: this.form?.produceMtEngine?.label || '',
              produceRatio: this.form.produceRatio.key == -1 ? null : this.form.produceRatio.key,
              produceRatioName: this.form.produceRatio.label,
              status: 1,
              supplierSpm: this.userInfo.id,
              address: this.cusAgencyInfo.address,
              currency: this.getCurrencyValue(this.cusAgencyInfo.currency),
              cusAgencyName: this.cusAgencyInfo.name,
              supplierName: this.cusAgencyInfo.supplierName,
              supplierAddress: this.cusAgencyInfo.supplierAddress,
            })
            this.loading = false
            this.newId = data
            this.showResult = true
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    onSubmitNewNeo() {
      this.$refs.newNeoThreeForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            const data = await this.$http('newProductionLine_2', {
              name: this.form.name,
              pid: this.newNeoForm.clientProject.id,
              field: this.form.field[this.form.field.length - 1],
              supplierPm: this.newNeoForm.supplierPm,
              status: 1,
              produceVersion: 2,
              quoteType: 1,
              accoutId: this.cusAgencyInfo.accoutId, //1212
              cusAgencyName: this.cusAgencyInfo.name, //'nihao'
              address: this.cusAgencyInfo.address, //'1111'
              supplierSpm: this.userInfo.id,
              currency: this.getCurrencyValue(this.cusAgencyInfo.currency), //1
              supplierName: this.cusAgencyInfo.supplierName, //'1111'
              supplierAddress: this.cusAgencyInfo.supplierAddress,
              discountList: this.form.discountList_2, //'1111', //
              updateProgramLineRequestList: this.updateProgramLineRequestList,
            })
            this.loading = false
            this.newId = data
            this.showResult = true
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    onBack() {
      this.$router.push({ name: 'customer', path: '/customer' })
    },
    onGoDetails(val) {
      this.$router.push({
        name: 'agency_agencyInfo',
        query: { id: val, extraName: this.cusAgencyInfo.name },
      })
    },
    // 根据currency字符来获取对应的value
    getCurrencyValue(val) {
      let data = this.$store.getters['app/getDictByType']('CURRENCY')
      let result = data.filter((item) => {
        if (item.dictLabel.split(';')[0] === val) {
          return item.dictValue
        }
      })
      return result[0].dictValue
    },
    onSelectProgram(value) {
      this.tableData = []
      this.updateProgramLineRequestList = []
      value.forEach((val) => {
        // let that = this
        let result = this.programList.filter((item) => {
          if (item.id === val) {
            this.updateProgramLineRequestList.push({
              pid: item.clientProjectId,
              programId: item.id,
              contentType: item.componentId,
              source: item.sourceCode,
              name: item.name,
            })
            return item
          }
        })
        this.tableData.push(...result)
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
